import React from "react";
import PropTypes from "prop-types";
import { FormWrapper } from "./styles";

const Form = ({ darkBg, children, onSubmit, ...restProps }) => {
	return (
		<FormWrapper
			noValidate
			darkBg={darkBg}
			onSubmit={onSubmit || ((e) => e.preventDefault())}
			{...restProps}
		>
			{children}
		</FormWrapper>
	);
};

Form.propTypes = {
	darkBg: PropTypes.bool,
};

Form.defaultProps = {
	darkBg: false,
};

export default Form;
